footer {
    background: var(--gray-100);

    div:first-child {
        @media (max-width: 450px) {
            flex-direction: column !important;
            padding: 15px 0;
        }
    }

    p {
        font-weight: 600;
        margin: 0;
        color: var(--dark-800);
        font-size: 0.875rem;

        &:nth-child(2) {
            margin-bottom: 10px;
        }
    }
}
