.header-pages {
    li {
        a {
            font-family: 'Saira Condensed', sans-serif;
            font-size: 1.125rem;
            font-weight: 600;
        }
    }

    @media (max-width: 600px) {
        flex-direction: column !important;

        div:first-child {
            align-items: initial !important;

            .header-nav {
                margin-top: 25px;
            }
        }
    }

    @media (max-width: 500px) {
        div:first-child {
            align-items: center !important;

            .header-nav {
                margin-top: 0px;
            }
        }

    }

    .btn-portal {
        border: 1px solid #000;

        span {
            font-family: 'Saira Condensed', sans-serif;
        }
        background: var(--white);

        :hover {
            background: var(--dark-400);
        }

        a:hover {
            text-decoration: none;
            color: var(--white);
        }

        @media (max-width: 600px) {
            position: relative;
            left: 120px;
            bottom: 64px;
        }

        @media (max-width: 500px) {
            position: static;
            margin-bottom: 10px;
        }
    }
}

.header-nav {
    grid-area: nav;

    a:hover {
        text-decoration: none;
        color: var(--gray-500);
    }
}


//h1 {
//    &.title {
//
//    }
//}
//
//h2 {
//    &.before-title {
//
//    }
//}
