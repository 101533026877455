.section-contato {
    color: var(--dark-400);

    h1{
        font-size: 1rem;
    }

    h2 {
        font-size: 2.5rem;
        color: var(--black);
    }
}

input[type="text"], input[type="email"], textarea {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid var(--dark-400) !important;
}

input[type="text"]:focus, input[type="email"]:focus, textarea:focus {
    background: transparent !important;
}

.form-contato {
    display: grid;
    grid-template-areas:
      "name email"
      "phone subject"
      "message message"
      "buttonSubmit .......";
}

.form-group {
    padding-bottom: 30px
}

.input-nome {
    grid-area: name;
    margin-right: 15px;
}

.input-email {
    grid-area: email;
}

.input-telefone {
    grid-area: phone;
    margin-right: 15px;
}

.input-assunto {
    grid-area: subject;
}

.input-message {
    grid-area: message;
}

.btn-submit {
    grid-area: buttonSubmit;
    width: 180px;
    height: 40px;
    text-transform: uppercase;
    padding: 5px;
    margin-top: 25px;
    background: var(--dark-800);
    border: 1px solid var(--dark-800);
    color: white;
    font-family: 'Saira Condensed', sans-serif;
    font-weight: 600;

    &:hover {
        background: var(--white);
        color: var(--black);
        border: 1px solid var(--dark-800);
    }
}

.mapa img {
    @media (max-width: 991px) {
        height: auto;
        margin-top: 20px;
    }
}

@media (max-width: 600px) {
    .form-contato {
        grid-template-areas:
          "name"
          "email"
          "phone"
          "subject"
          "message"
          "buttonSubmit";
    }
}
