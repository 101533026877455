.list-arquivos {
    li {
        :hover {
            color: var(--gray-500);
            background: #eceff1;
        }

        p {
            color: var(--dark-400);
            font-weight: 600;
        }

        span {
            font-family: 'Saira Condensed', sans-serif;
            font-weight: 600;
            color: var(--dark-800);
        }

        a {
            text-decoration: none;
        }

        border: 2px solid #ededed;

        .desc-arquivo {
            p {
                line-height: 1rem;
                overflow: hidden;
            }

            p:last-child {
                font-size: .7rem;
            }
        }
    }
}
