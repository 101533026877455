.section-historia {
    background: var(--gray-100);
    color: var(--dark-400);
    max-height: 650px;

    .img-historia {
        z-index: 100;
    }

    .img-historia-fundo {
        position: relative;
        right: 100px;
        width: 400px;
        @media (max-width: 1100px) {
            width: 300px;
        }
        @media (max-width: 991px) {
            display: none;
        }
    }
}
