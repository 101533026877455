* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --white: #ffffff;
    --black: #000000;
    --gray-100: #dedede;
    --gray-500: #898989;
    --dark-400: #464646;
    --dark-800: #1b1b1B;
}

// @media (max-width: 1080px) {
@media (max-width: 1200px) {
    html {
        font-size: 93.75%;
    }
}

@media (max-width: 720px) {
    html {
        font-size: 87.5%;
    }
}

body {
    font: 400 1rem 'IBM Plex Serif', serif;
}

p, a {
    font: 400 1rem 'IBM Plex Serif', serif;
}

p {
    line-height: 1.5;
    color: #676767;
}

label {
    font: 600 1rem 'IBM Plex Serif', serif;
    color: var(--dark-800)
}

input, textarea, select, button {
    font: 400 0.875rem 'IBM Plex Serif', serif;
    color: var(--dark-400);
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Saira Condensed', sans-serif;
    font-weight: 600;
}

h1 {
    font-size: 1rem;
    color: var(--gray-500);
}

h2 {
    font-size: 2.5rem;
    color: var(--dark-800);
}

h3 {
    font-size: 1.5rem;
}
button {
    cursor: pointer;
}

a {
    color: inherit;
    text-decoration: none;
}

li {
    list-style: none;
}
