.background-index {
    min-height: 600px;
    max-width: 1280px;
    background-image: url('../../img/header-background.png');
    background-repeat: no-repeat;
    background-size: contain, cover;
    background-position: right;

    @media (max-width: 991px) {
        background-image: none;
        div:last-child {
            justify-content: center !important;

        }
    }

    .background-index-medium {
        @media (max-width: 991px) {
            padding: 0 !important;
            min-height: 600px;
            background-image: url('../../img/header-background.png');
            background-repeat: no-repeat;
            background-size: cover, cover;
            background-position: bottom;

        }
        @media (max-width: 500px) {
            min-height: 350px !important;
        }
    }

    .header-sloganbox {
        h1 {
            color: var(--white);
            font-size: 3rem;
        }

        h2 {
            font: 400 1.25rem 'IBM Plex Serif', serif;
            margin-bottom: 2.125rem;
            color: #B0B0B0;
        }

        width: 500px;
        color: var(--white);
        background: var(--dark-800);
        padding: 30px 30px;
        z-index: 10;

        @media (max-width: 991px) {
            width: 600px;
            position: relative;
            bottom: 100px;
            padding: 40px;
        }
        @media (max-width: 520px) {
            width: 100%;
            bottom: 0;
        }
        p:first-child{
            font-size: 2rem;
        }

        a {
            font-family: 'Saira Condensed', sans-serif;
            font-weight: 600;
            color: var(--white);
            border: 0.031rem solid var(--white);
            padding: 0.625rem 1.25rem;
        }

        a:hover {
            background: var(--white);
            color: var(--black);
            text-decoration: none;
        }
    }
}
