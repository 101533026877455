.servicos-container {
    margin-top: 150px;

    .servicos-items {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        @media (max-width: 991px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 767px) {
            grid-template-columns: 1fr;
        }

        li {
            // :hover{background: var(--black)}
        }

        .servicos-item {
            box-shadow: 3px 3px 10px 2px rgb(0 0 0 / 50%);
            margin: 20px 10px 20px 10px;
            padding: 30px 0 30px 0;

            :hover {
                cursor: pointer;
            }

            span {
                font-family: 'Saira Condensed', sans-serif;
                font-weight: 600;
                font-size: 1.5rem;

                &::after {
                    content: "";
                    width: 40px;
                    height: 1px;
                    display: block;
                    margin: 5px auto;
                    background: var(--black);
                }
            }
        }
    }
}
