.section-projetos {
    background: var(--black);
    color: var(--white);

    p {
        color: #d0d0d0;
    }

    h1 {
        font-size: 1rem;
        color: var(--gray-100);
    }
    h2 {
        font-size: 2.5rem;
        color: var(--white);
    }

    span {
        font-family: 'Saira Condensed', sans-serif;
        font-weight: 700;
    }

    .slide-total, .separator {
        color: #818181;
    }

    .separator {
        color: #2d2d2d;
        font-weight: 400;
        font-size: 1.6875rem;
        margin-bottom: 2.5px;
    }

    .carousel-control-prev, .carousel-control-next {
        position: static;
        border: 1px solid var(--white);
        background: var(--dark-400);
    }

    .carousel-item {
        h1 {
            position: relative;
            top: 300px;
            left: 800px;
            z-index: 2000 !important;
        }

        img {
            height: 400px;
            @media (max-width: 600px){ height: 350px; }
            @media (max-width: 500px){ height: 300px; }
            @media (max-width: 400px){ height: 250px; }
        }
    }

    .slide-atual, .slide-total{
        font-size: 2rem;
    }

    .projeto-content {
        margin-top: 30px;
        @media (max-width: 767px) {
            display: grid !important;
            gap: 20px;
            grid-template-columns: 3fr 1fr;
            grid-template-areas:
                "descricao controles";
            .desc-projetos {
                grid-area: descricao;
            }
            .projeto-controles {
                grid-area: controles;
            }
        }
        @media (max-width: 459px) {
            gap: 0;
            grid-template-columns: 1fr;
            grid-template-areas:
                "descricao" "controles";
        }
    }
}
