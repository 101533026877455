.section-name-page {
    background: var(--dark-800);

    h1 {
        font-size: 2.5rem;
        color: var(--white);
    }
}

.form-portal {
    display: grid;
    grid-template-areas:
      "titulo titulo"
      "email senha"
      "submit submit"
      "esqueceu-senha esqueceu-senha";
    padding: 30px;

    h5 {
        grid-area: titulo;
        padding: 20px
    }

    p {
        margin-left: 20px;
    }

    .email {
        grid-area: email;
        padding: 20px
    }

    .senha {
        grid-area: senha;
        padding: 20px
    }
}



.form-portal button {
    grid-area: submit;
    width: 150px;
    padding: 10px !important;
    text-transform: uppercase;
    background: #000 !important;
    color: #FFF !important;
    font-weight: 500;
    border: solid;
    margin-left: 15px;
}

.esqueceu-senha {
    grid-area: esqueceu-senha;
    padding: 20px

}

.form-portal button:hover {
    background: #FFF !important;
    color: #000 !important;
    cursor: pointer !important;
    border: solid 1px #000 !important;
}

.card {
    padding: 20px;
    width: 400px;
    height: 440px;
    box-sizing: border-box;
    color: #464646;
    box-shadow: 3px 3px 10px 2px rgb(0 0 0 / 50%);
    position: relative;
    top: -30px;

    @media (max-width: 1000px) {
        height: 350px;
        width: 310px;
    }
}

.card-img-top {
    padding-top: 20px;;
    width: 70px;
    margin: 0 auto;
}

.card-title {
    font-family: 'IBM Plex Serif', serif;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    padding: 10px;
}

.card-body {
    font-weight: 400;
    font-size: 1em;
    text-align: center;
    padding: 10px 0;
}

.card-text {
    font-size: .9em;
    padding: 20px 0;

}

// p span{font-weight: bold;}
.form-control {
    border: none;
    border-bottom: 1px solid #ccc;
}

@media (max-width: 1000px) {
    .page-portal {
        flex-direction: column;
    }
}

@media (max-width: 500px) {
    .form-portal {
        grid-template-areas:
          "titulo"
          "email"
          "senha"
          "submit"
          "esqueceu-senha";
    }
}
