@charset "utf-8";

// Fonts
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@400;600&family=Saira+Condensed:wght@600;700&display=swap');

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Reset Styles
@import "reset";

@import "main";
@import "header";
@import "historia";
@import "servicos";
@import "projetos";
@import "contato";
@import "pagination";
@import "footer";

@import "login";
@import "portal";
